/*
 Copyright (C) COMPOFFICE-R Kft, RentIT - All Rights Reserved
 Unauthorized copying of this file, via any medium is strictly prohibited
 Proprietary and confidential

 */
var SpeakerPresentation = (function () {
    'use strict';
    /* ----- Declare Valiables ---------------------------------------------------------------------------------------- */
    let
        playbackController,
        soundController,
        presentationClock,
        debounce = false,
        initial_time = 0,
        speak_timer = 0,
        speak_timeout,
        on_air = false,
        speakers = [],
        started = false,
        channels = [],
        presi_id,
        timeout;


    function Init() {
        /* ----- iSpring Connector ---------------------------------------------------------------------------------------- */
        window.ispringPresentationConnector = {};
        ispringPresentationConnector.register = function (player) {
            playbackController = player.view().playbackController();
            playbackController.gotoFirstSlide(true);
            presentationClock = playbackController.clock();
            soundController = player.view().soundController();
            soundController.mute(true);
            /*if ( localState.presentationState ) {
             var s = localState.presentationState;
             playbackController.gotoTimestamp( s.slideIndex, s.stepIndex, s.timeOffset, true );
             }*/

            playbackController.slideChangeEvent().addHandler(function(slideIndex)
            {
                /*if (on_air) {
                    $.ajax({
                        method: 'post',
                        url: 'api/',
                        data: {
                            do: "savePresentationCurrentSlide",
                            id: presi_id,
                            slide_index: slideIndex
                        }
                    });
                }

                console.log("change");

                if ((AppData.profile.group === "speaker" || AppData.profile.group === "director") && on_air) {
                    Socket.Send('Presentation.gotoSlide', slideIndex, {
                        client: ['display', 'client'],
                        channel: channels.length == 0 ? [localState.currentChannel] : channels
                    });
                }*/
            });
        };

        $(document).on('keydown', function (e) {
            if ($('.speaker_presentation').is(':visible')) {
                var
                    code = e.wdich || e.keyCode,
                    key = e.key;
                /* Allowed Keys */
                if (['F11', 'F12'].includes(key)) {
                    return true;
                } else {
                    e.preventDefault();
                }
                /* Next Slide */
                if (['PageDown', ' ', 'ArrowDown', 'ArrowRight'].includes(key)) {
                    nextSlide();
                    //Socket.Send( 'Presentation.gotoTimestamp', localState.presentationState );
                    //Socket.Send('Presentation.nextSlide', true);
                }
                /* Prevouos Slide */
                if (['PageUp', 'Backspace', 'ArrowUp', 'ArrowLeft'].includes(key)) {
                    prevSlide();
                    //Socket.Send( 'Presentation.gotoTimestamp', localState.presentationState );
                    //Socket.Send('Presentation.prevSlide', true);
                }
                /* First Slide*/
                if (['Escape', 'F5'].includes(key)) {
                    firstSlide();
                    //Socket.Send( 'Presentation.gotoTimestamp', localState.presentationState );
                    //Socket.Send('Presentation.firstSlide', true);
                }
                /* Blank Screen */
                if (['.'].includes(key)) {
                    toggleBlank();
                    //Socket.Send('Presentation.toggleBlank', true);
                }
            }
        });

        onAir({presentations: AppData.presentations});
    }

    function setTimer(time) {
        clearInterval(speak_timeout);
        started = false;
        if (time === "" || isNaN(time)) {
            time = 0;
        }
        speak_timer = parseInt(time);
        initial_time = parseInt(time);
        $(".timer", $(".speaker_presentation")).removeClass("warning finalwarning");
        showTime();
    }

    function startTimer() {
        if (!started && speak_timer > 0) {
            $(".timer", $(".speaker_presentation")).removeClass("warning finalwarning");
            speak_timeout = setInterval(function () {
                speak_timer--;
                if (speak_timer < 0) {
                    speak_timer = 0;
                }

                if (speak_timer < initial_time * 0.2) {
                    $(".timer", $(".speaker_presentation")).addClass("warning");
                }

                if (speak_timer < initial_time * 0.1) {
                    $(".timer", $(".speaker_presentation")).removeClass("warning").addClass("finalwarning");
                }

                showTime();
            }, 1000);
            started = true;
        }
    }

    function pauseTimer() {
        clearInterval(speak_timeout);
        started = false;
    }

    function resetTimer() {
        clearInterval(speak_timeout);
        started = false;
        speak_timer = 0;
        initial_time = 0;
        showTime();
        $(".timer", $(".speaker_presentation")).removeClass("warning finalwarning");
    }

    function onAir(data) {
        //on_air = parseInt(state.state) === 1 ? true : false;
        AppData.presentations = data.presentations;

        channels = [];

        $.each(AppData.presentations, function (i, item){
            if (item.speaker.indexOf(AppData.profile.id) > -1 && item.onair == 1) {
                channels.push(item.channel);
            }
        });
    }

    function setSpeakers(data) {
        speakers = data;
    }

    function showTime() {
        var sec_num = parseInt(speak_timer, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours   < 10) { hours   = "0"+hours; }
        if (minutes < 10) { minutes = "0"+minutes; }
        if (seconds < 10) { seconds = "0"+seconds; }

        $(".timer", $(".speaker_presentation")).html(hours+':'+minutes+':'+seconds);
    }

    /* ----- Control: Goto TimeStamp ---------------------------------------------------------------------------------- */
    function gotoTimestamp(slideIndex, stepIndex, timeOffset) {
        playbackController.gotoTimestamp(slideIndex, stepIndex, timeOffset);
        saveLocalState();
    }

    function gotoSlide(slide) {
        playbackController.gotoSlide(slide);
        saveLocalState();
    }

    /* ----- Control: First Slide ------------------------------------------------------------------------------------- */
    function firstSlide() {
        playbackController.gotoFirstSlide();
        saveLocalState();
    }

    /* ----- Control: Next Slide -------------------------------------------------------------------------------------- */
    function nextSlide() {
        playbackController.gotoNextStep();
        trySyncVideo();
        saveLocalState();

        console.log("next");

        if (AppData.profile.presi_control == 1) {
            if (channels.length > 0) {
                Socket.Send('Presentation.nextSlide', null, {
                    client: ['display', 'client'],
                    channel: channels
                });
            }

            Socket.Send('SpeakerPresentation.nextSlideOwn', {client: "client"}, {"uid": speakers});
        }
    }

    function nextSlideOwn() {
        playbackController.gotoNextStep();
        trySyncVideo();
        saveLocalState();
    }

    /* ----- Control: Prevous Slide ----------------------------------------------------------------------------------- */
    function prevSlide() {
        playbackController.gotoPreviousSlide();
        trySyncVideo();
        saveLocalState();


        if (AppData.profile.presi_control == 1) {
            if (channels.length > 0) {
                Socket.Send('Presentation.prevSlide', null, {
                    client: ['display', 'client'],
                    channel: channels
                });
            }

            Socket.Send('SpeakerPresentation.prevSlideOwn', {client: "client"}, {"uid": speakers});
        }
    }

    function prevSlideOwn() {
        playbackController.gotoPreviousSlide();
        trySyncVideo();
        saveLocalState();
    }

    /* ----- Control: Blank Screen Toggle ----------------------------------------------------------------------------- */
    function toggleBlank() {
        $('#iSpringFrame').contents().find('body').toggle();
    }

    /* ----- Control: iFrame Source ----------------------------------------------------------------------------------- */
    function source(src) {

        var presi = AppData.medias.find(function (item) {
            return item.id == src;
        });

        var path = "";

        if (presi != undefined && presi.files[localState.lang] != undefined) {
            path = presi.files[localState.lang].foldername + "/";
            presi_id = src;
        }

        var
            $embed = $('#iSpringFrame'),
            $url = path + 'index.html';
        /* Change */
        if (src && $url !== $embed.attr('src')) {
            $embed.attr('src', $url);
            localState.presentation = src;
        }
        /* Remove*/
        if (!src) {
            setTimeout(function () {
                $embed.removeAttr('src');
                delete localState.presentation;
                delete localState.presentationState;
            }, 1000);
        }

        if (AppData.profile.presi_control == 1) {
            $(".prev_frame, .next_frame, .timer", $(".speaker_presentation")).show();

            $(".prev_frame", $(".speaker_presentation")).unbind("click").on("click", prevSlide);
            $(".next_frame", $(".speaker_presentation")).unbind("click").on("click", nextSlide);
        } else {
            $(".prev_frame, .next_frame, .timer", $(".speaker_presentation")).hide();
        }
    }

    /* ----- VideoPlayer Time Code -------------------------------------------------------------------------------------- */
    function trySyncVideo() {
        $('#iSpringFrame').contents().find('video').each(function () {
            var self = this;
            self.ontimeupdate = function () {
                if (!debounce) {
                    debounce = true;
                    Socket.Send('Presentation.syncVideo', self.currentTime);
                    timeout = setTimeout(function () {
                        debounce = false;
                    }, 1000 / 60 * 45);
                }
            };
        });
    }

    /* ----- Save Last State ------------------------------------------------------------------------------------------ */
    function saveLocalState() {
        localState.presentationState = {
            slideIndex: presentationClock.timestamp().slideIndex(),
            stepIndex: presentationClock.timestamp().stepIndex(),
            timeOffset: presentationClock.timestamp().timeOffset()
        };
    }

    function show() {

        /*var streamplayer = videojs('my-video');
        streamplayer.pause();
        $("#landing").find("#playerElement").hide();
        $("#landing").find(".videostream").hide();*/

        if ($(".presentation").is(":visible")) {
            $('#iSpringFrame_attendee').get(0).contentWindow.postMessage({action: "removeSrc"});
            $(".presentation").hide(400);
        }

        $(".speaker_presentation").fadeIn(400);
    }

    function hide() {
        $(".speaker_presentation").fadeOut(400);
    }

    /* ----- Process on Document Ready ---------------------------------------------------------------------------------- */
    $(function () {
        if (localState.presentation) {
            //Presentation.source(localState.presentation);
        }
    });
    /* ----- Public API ----------------------------------------------------------------------------------------------- */
    return {
        Init: Init,
        gotoTimestamp: gotoTimestamp,
        gotoSlide: gotoSlide,
        firstSlide: firstSlide,
        nextSlide: nextSlide,
        prevSlide: prevSlide,
        nextSlideOwn: nextSlideOwn,
        prevSlideOwn: prevSlideOwn,
        toggleBlank: toggleBlank,
        setTimer: setTimer,
        startTimer: startTimer,
        pauseTimer: pauseTimer,
        resetTimer: resetTimer,
        onAir: onAir,
        show: show,
        hide: hide,
        source: source,
        setSpeakers: setSpeakers,
        playbackController: playbackController
    };
})();
